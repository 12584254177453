<template>
  <!-- 角色管理 -->
  <div class="container">
    <div class="head">
      <div class="search_main">
        <div class="status">
          <router-link to="/role/add">
            <div class="addBtn" style="margin-right: 10px;">
              <img src="../../../assets/images/add.png" style="margin-right: 5px;width: 13px;">添加
            </div>
          </router-link>
        </div>
      </div>
      <div class="search_main">
        <div class="status">
          <div>角色状态</div>
          <div>
            <el-select v-model="queryinfo.enabled" placeholder="请选择角色状态">
              <el-option value="" label="全部"></el-option>
              <el-option v-for="item in options" :key="item.id" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="search_main">
        <div class="status">
          <div>角色用户</div>
          <div>
            <el-input placeholder="请输入角色名称" v-model="queryinfo.name" clearable>
            </el-input>
          </div>
        </div>
      </div>
      <div class="search_main">
        <div class="status1" style="display: flex">
          <div class="demandBtn" style="margin-left: 0px;" @click="search">
            <img src="../../../assets/images/search.png" alt="" /> 查询
          </div>
          <div class="resetBtn" @click="reset">
            <img src="../../../assets/images/reset.png" alt="" />重置
          </div>
        </div>
      </div>
    </div>


    <div style="height: 10px"></div>
    <div class="body">
      <div class="dataTable">
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" :header-cell-style="tableHeaderColor"
          style="font-family: PingFangRoutine;" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }">
          <el-table-column prop="name" label="角色名称" align="center"> </el-table-column>
          <el-table-column prop="code" label="角色CODE" align="center"> </el-table-column>
          <el-table-column prop="remark" label="描述" align="center"> </el-table-column>
          <el-table-column prop="createTime" label="添加时间" width="300" align="center">
          </el-table-column>
          <el-table-column prop="enabled" label="是否启用" align="center">
            <template v-slot:default="scope">
              <el-switch :value="scope.row.enabled" active-color="rgb(64,158,255)" inactive-color="rgb(138,138,138)"
                @change="changecomposestatus(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="300">
            <template #default="scope">
              <div class="operating">
                <div class="edit" @click="roleedit(scope.row.id)">
                  <img style="width: 18px;height: 18px;" src="../../../assets/images/edit.png"/>
                </div>
                <div class="delete" @click="roledel(scope.row.id)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="height: 10px"></div>
    <div class="footer">
      <!-- 分页 -->
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-size="10"
            layout="total, prev, pager, next, jumper" :total="total">
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
      <!-- 分页 -->
    </div>
  </div>
</template>
<script>
import { roleList, rolestatus, roleDelete } from "@/api/role";
import "../../../font-style/font.css";
export default {
  data() {
    return {
      queryinfo: {
        currPage: "",
        enabled: "",
        name: "",
      },
      options: [
        { id: 1, value: "true", label: "启用" },
        { id: 2, value: "false", label: "停用" },
      ],
      tableData: [],
      total: null,
    };
  },
  activated() {
    this.list();
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;fontSize: '14px'";
      }
    },
    //角色列表
    list() {
      roleList(this.queryinfo).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total * 1;
      });
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.currPage = val;
      this.list();
    },
    //开启停用角色
    changecomposestatus(val) {
      rolestatus({ id: val.id }).then((res) => {
        if (res.data !== true) {
          return;
        }
        this.$notify({
          title: val.enabled === true ? "停用" : "启用",
          message: val.enabled === true ? "已停用！" : "已启用",
          type: "success",
        });
        this.list();
      });
    },
    //搜索
    search() {
      this.total = 0
      this.queryinfo.currPage = ""
      this.list();
    },
    // 重置
    reset() {
      this.total = 0
      this.queryinfo.currPage = ""
      this.queryinfo.enabled = "",
        this.queryinfo.name = "",
        this.list()
    },
    //删除
    async roledel(id) {
      if (!(await this._box())) return;
      roleDelete({ id }).then((res) => {
        if (res.status !== 10000) {
          this.$notify({
            title: "警告",
            message: res.msg,
            type: "warning",
          });
          return;
        }
        let totalPage = Math.ceil((this.total - 1) / 10);
        let currentPage =
          this.queryinfo.currPage > totalPage
            ? totalPage
            : this.queryinfo.currPage;
        this.queryinfo.currPage = currentPage < 1 ? 1 : currentPage;
        this.list();
      });
    },
    //编辑
    roleedit(id) {
      this.$router.push({ path: "/role/edit", query: { roleid: id } });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../assets/css/common.less";

.head {
  padding-top: 7px;
  padding-bottom: 17px;
  padding-left: 20px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
</style>
